@use './variables' as var;
@use './mixins' as mixins;
@use './themes/theme-mixins' as themeMixins;
@use "sass:map";

@import 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap';
* {
  outline: none !important;
}

html {
  font-size: map.get(var.$typography, normal);
}

body {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  min-width: 1024px;
  height: 100vh;
}

.mh-100 {
  min-height: 100%;
}

.mat-drawer-container {
  height: 100%;
}

.h-0 {
  height: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}

.unstyled-list {
  li {
    list-style-type: none;
  }
}

// Allow focus but remove other styles from link like elements
a {
  @include themeMixins.theme-color(primary, 400);
  &.navigation {
    color: inherit;
    &:focus {
      text-decoration: underline;
    }
  }
}
.unset {
  all: unset;
  &:hover {
    all: unset;
    color: unset;
  }
}
// #region customization
// We use bootstrap grid, so to keep up with design we out to have 8px gap instead of default 15 between mat-greed elements
.cards-row {
  @media screen and (min-width: var.$average) {
    >div[class^="col-"] {
      &:not(:last-child){
        padding-right: 10px;
      }
      &:not(:first-child){
        padding-left: 10px;
      }
    }
  }

}

// #endregion

// TODO: find better solution
.nav-list, .unstyled-list {
  li {
    list-style-type: none;
    padding-bottom: map.get(var.$portal_spacers, 2);
  }
  a {
    text-decoration: none;
    @include themeMixins.theme-color(primary, 500);
    @include mixins.transition(color);
    &:hover {
      @include themeMixins.theme-color(accent, 500);
    }
  }
}

.invisible {
  visibility: hidden!important;
}

.button-link {
  @include themeMixins.theme-color(primary, 500);
}

.bg-transparent {
  background-color: transparent !important;
}

.mat-icon{
  @include mixins.mat-icon-size();
}

.pull-right{
  float: right;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
}

.white-space{
  white-space: pre-wrap;
}

.clearfix {
  overflow: auto;
}

.align-center{
  text-align: center;
}
// Add vertical scroll on tablets in order to cope with design changes in home-component
@include mixins.respond-to(average){
  .mat-sidenav-content{
    height: 100%!important;
    >.router-content{
      height: auto!important;
    }
  }
}

// TODO use grid or flex to add space between rows
.mat-mdc-card {
  padding: map.get(var.$portal_spacers, 4)!important;
  margin-bottom: 20px;
  border-radius: 16px !important;
  box-shadow: 0 18px 32px rgba(map.get(var.$mat-grey, 500), .05) !important;
}

.mat-list-reset{
  padding: 0!important;
  .mdc-list-item-content{
    padding: 0!important;
  }
  .mdc-list-item{
    height: auto!important;
  }
}

.mat-button-reset{
  cursor: default!important;
  min-width: 0!important;
  line-height: inherit!important;
  padding: 0 6px!important;
}

//#region replace with bootstrap
// TODO: replace with default bootstrap one
.overflow-auto {
  overflow: auto;
}

.scrollable {
  overflow: auto;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

//#endregion

.cup{
  cursor: pointer;
}

.buttons {
  .small-btn{
    padding: 0;
    min-width: 36px;
  }
}

button[mat-flat-button] {
  text-transform: capitalize;
  border-radius: 22px;
  padding: 2px 10px;
}

button[mat-icon-button] {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mdc-button:not(.mat-mdc-menu-trigger) {
  height: 2.5rem !important;
  font-weight: 600;

  mat-icon {
    height: 1.5rem !important;
    font-size: 1.5rem !important;
    width: 1.5rem !important;
  }
}

.basic-btn {
  background-color: var.$light-primary-text !important;
}

button[mat-stroked-button] {
  text-transform: capitalize;
  border-radius: 22px;
  @include themeMixins.theme-color(primary, 500);
  background-color: inherit;
  border: 1px solid;
  padding: 0 20px !important;
  height: max-content;

  &:not(.mat-button-disabled) {
    @include themeMixins.theme-border-color(primary, 500);
  }

  &.light {
    background-color: var.$light-primary-text;
  }

  mat-icon {
    width: 1.5rem !important;
    height: 1.5rem !important;
    font-size: 1.5rem !important;
  }
}

button[mat-mini-fab] {
  .mdc-button__label {
    mat-icon {
      line-height: normal;
    }
  }

  &.light {
    background-color: #ffffff;
    &[disabled] {
      mat-icon {
        color: map.get(var.$mat-grey, 400);
      }
    }
    &:hover {
      background-color: map.get(var.$mat-grey, 100);
    }
  }
  box-shadow: none;
  &:focus, &:active, &:hover, &:focus:active {
    box-shadow: none;
  }
}

.icon-action-button {
  background-color: map.get(var.$mat-grey, 100);
  color: map.get(var.$mat-grey, 700);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: inherit;
  border: none;

  &:hover {
    background-color: map.get(var.$mat-grey, 300);
  }

  &:focus {
    background-color: map.get(var.$mat-grey, 400);
  }
}

.transparent-colored-background {
  background-color: rgba(map.get(var.$mat-grey, 800), .8) !important; //doesn't work by default
}

.disabled-row {
  background-color: rgba(map.get(var.$mat-grey, 400), .4);
}

.top-right-position {
  position: absolute;
  right: - map.get(var.$portal_spacers, 1);
  top: - map.get(var.$portal_spacers, 1);
}

.small-text {
  font-size: .8rem;
}

.word-break {
  word-break: break-word;
}

$breadcrumb-height: 20px;
.full-page-height {
  height: calc(100% - #{$breadcrumb-height});
}

[dir=rtl] {
  .mat-calendar-previous-button,
  .mat-calendar-next-button {
    transform: none !important;
  }
}

// snackbar
.mat-mdc-snack-bar-container, .mdc-snackbar__surface {
  border-radius: 5px;
}

.mat-mdc-checkbox, .mat-mdc-slide-toggle, .mat-mdc-radio-button {
  label {
    margin-bottom: 0;
  }
}

.mat-mdc-slide-toggle {
  padding: 0 6px;
}

// tabs
.mat-mdc-tab-group {
  .mat-mdc-tab {
    opacity: 0.6;
    padding: 0 map.get(var.$portal_spacers, 4);

    &.mdc-tab--active {
      opacity: 1;
    }
  }

  .mat-mdc-tab-body-wrapper {
    padding-top: 20px;
  }

  .mat-mdc-tab-header {
    border-bottom: 1px solid map.get(var.$mat-grey, 300);
  }

  .mat-mdc-tab:hover .mdc-tab__ripple::before {
    opacity: 0;
  }

  .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
    font-weight: 600;
    font-size: map.get(var.$typography, small);
    text-transform: uppercase;
    color: var.$color_1;
  }
}


//TODO: change caption prop in the typography when we will migrate that
.mat-mdc-tooltip {
  font-size: 0.625rem;
}

.mat-mdc-dialog-actions {
  padding: 8px 24px 12px 24px !important;
}

.mat-mdc-dialog-content {
  color: rgba(0,0,0,.87) !important;
}

.mat-title {
  font-size: 1rem;
  font-weight: 500;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #c4c4c4;
}
