@use '../themes/theme-mixins' as themeMixins;

@mixin mat-mdc-progress-bar-overwrites {
  .mat-mdc-progress-bar {
    &.mat-primary .mdc-linear-progress__buffer-bar {
      background-color: themeMixins.get-theme-color(500, 0.25, "primary");
    }
    &.mat-accent .mdc-linear-progress__buffer-bar {
      background-color: themeMixins.get-theme-color(500, 0.25, "accent");
    }
    &.mat-warn .mdc-linear-progress__buffer-bar {
      background-color: themeMixins.get-theme-color(500, 0.25, "warn");
    }
  }
}
