@use '../themes/theme-mixins' as themeMixins;

@mixin mat-mdc-button-overwrites {
  $color-primary: themeMixins.get-theme-color(500, null, "primary", true);
  $color-accent: themeMixins.get-theme-color(500, null, "accent", true);
  $color-warn: themeMixins.get-theme-color(500, null, "warn", true);
  $color-primary-ripple: themeMixins.get-theme-color(500, 0.1, "primary");
  $color-accent-ripple: themeMixins.get-theme-color(500, 0.1, "accent");
  $color-warn-ripple: themeMixins.get-theme-color(500, 0.1, "warn");

  .mat-mdc-icon-button {
    html &,
    .theme-dark & {
      &.mat-primary {
        --mat-icon-button-ripple-color: #{$color-primary-ripple};
      }
      &.mat-accent {
        --mat-icon-button-ripple-color: #{$color-accent-ripple};
      }
      &.mat-warn {
        --mat-icon-button-ripple-color: #{$color-warn-ripple};
      }
    }
  }

  .mat-mdc-button {
    &,
    .theme-dark & {
      &.mat-primary {
        --mat-text-button-ripple-color: #{$color-primary-ripple};
      }

      &.mat-accent {
        --mat-text-button-ripple-color: #{$color-accent-ripple};
      }

      &.mat-warn {
        --mat-text-button-ripple-color: #{$color-warn-ripple};
      }
    }
  }

  .mat-mdc-outlined-button {
    &,
    .theme-dark & {
      &.mat-primary {
        --mat-outlined-button-ripple-color: #{$color-primary-ripple};
      }

      &.mat-accent {
        --mat-outlined-button-ripple-color: #{$color-accent-ripple};
      }

      &.mat-warn {
        --mat-outlined-button-ripple-color: #{$color-warn-ripple};
      }
    }
  }

  .mat-mdc-unelevated-button:not(:disabled),
  .mat-mdc-raised-button:not(:disabled) {
    &.mat-primary {
      --mdc-filled-button-label-text-color: #{$color-primary};
      --mdc-protected-button-label-text-color: #{$color-primary};
    }
    &.mat-accent {
      --mdc-filled-button-label-text-color: #{$color-accent};
      --mdc-protected-button-label-text-color: #{$color-accent};
    }
    &.mat-warn {
      --mdc-filled-button-label-text-color: #{$color-warn};
      --mdc-protected-button-label-text-color: #{$color-warn};
    }
  }
}
