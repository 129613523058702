@use '../themes/theme-mixins' as themeMixins;

@mixin mat-mdc-checkbox-overwrites {
  $color-primary: themeMixins.get-theme-color(500, null, "primary", true);
  $color-accent: themeMixins.get-theme-color(500, null, "accent", true);
  $color-warn: themeMixins.get-theme-color(500, null, "warn", true);

  .mat-mdc-checkbox {
    &.mat-primary {
      --mdc-checkbox-selected-checkmark-color: #{$color-primary};
    }
    &.mat-accent {
      --mdc-checkbox-selected-checkmark-color: #{$color-accent};
    }
    &.mat-warn {
      --mdc-checkbox-selected-checkmark-color: #{$color-warn};
    }
  }
}
