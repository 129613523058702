.tui-image-editor-header {
  display: none;
}

.tie-btn-reset, .tie-btn-mask {
  display: none !important;
}

.tui-image-editor-container {
  svg {
    vertical-align: unset;
  }
}

.tui-image-editor-help-menu {
  .normal  {
    display: none !important;
  }
  .hover  {
    display: none !important;
  }
  .disabled  {
    display: block !important;
  }
}

.portal-editor .tui-image-editor-container.bottom .tui-image-editor-submenu > div {
  padding-bottom: 0;
}
