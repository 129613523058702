@use "./variables" as var;
@use "./themes/theme-mixins" as themMixins;
@use 'sass:meta';

@mixin respond-to($media) {
  @if $media == small {
    @media only screen and (max-width: var.$small) {
      @content;
    }
  } @else if $media == average {
    @media only screen and (max-width: var.$average - 1) {
      @content;
    }
  } @else if $media == large {
    @media only screen and (min-width: var.$large) {
      @content;
    }
  } @else if $media == extralarge {
    @media only screen and (min-width: var.$extralarge) {
      @content;
    }
  } @else if $media == maxextralarge {
    @media only screen and (max-width: var.$extralarge - 1) {
      @content;
    }
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }
  &:-moz-placeholder {
    @content
  }
  &::-moz-placeholder {
    @content
  }
  &:-ms-input-placeholder {
    @content
  }
}

@mixin horizontal-gradient($from, $to, $stop: 100%) {
  /* fallback/image non-cover color */
  background-color: $from;

  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(left, $from 0%, $to $stop);

  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, $from), color-stop($stop, $to));

  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(left, $from 0%, $to $stop);

  /* Opera 11.10+ */
  background-image: -o-linear-gradient(left, $from 0%, $to $stop);

  /* IE10+ */
  background: -ms-linear-gradient(left, $from 0%, $to $stop);

  /* Standard */
  background: linear-gradient(to right, $from 0%, $to $stop);

  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$from', endColorstr='$to', GradientType=1);

}

@mixin dot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  display: block;
}

@mixin flex($justify-content: space-between, $align-items: center, $flex-direction: row) {
  justify-content: $justify-content;
  align-items: $align-items;
  display: flex;
  flex-direction: $flex-direction;
}


@mixin flex-bottom {
  @include flex(space-between, flex-end);
}

@mixin flex-center {
  @include flex(center, center);
}

@mixin flex-center-left {
  @include flex(flex-start, center);
}

@mixin flex-center-right {
  @include flex(flex-end, center);
}

@mixin flex-center-top {
  @include flex(space-between, flex-start);
}

@mixin flex-left-top {
  @include flex(flex-start, flex-start);
}

@mixin flex-left-bottom {
  @include flex(flex-start, flex-end);
}

@mixin flex-column {
  @include flex(flex-start, stretch, column);
}

@mixin transition($prop: all, $duration: 300ms,  $animation: ease ) {
  transition: $prop $duration $animation;
}

@mixin mat-icon-size($size: var.$icon-size) {
  font-size: $size;
  height: $size;
  width: $size;
  line-height: 1;
}

@mixin box-shadow($x-axis, $y-axis, $blur, $color) {
  box-shadow: $x-axis $y-axis $blur $color;
}

@mixin box-shadow-2($x-axis, $y-axis, $blur, $color, $x-axis-2, $y-axis-2, $blur-2, $color-2) {
  box-shadow: $x-axis $y-axis $blur $color, $x-axis-2 $y-axis-2 $blur-2 $color-2;
}

@mixin set-color-classes($colors) {
  @each $color-name, $color in $colors {
    .#{$color-name}-color {
      background-color: $color;
    }
  }
  .primary-color {
    @include themMixins.theme-background-color(primary, 500);
  }

  .accent-color {
    @include themMixins.theme-background-color(accent, 500);
  }
}

@mixin drag-and-drop {
  .draggable {
    .tree-node {
      &:hover {
        cursor: move;
      }
    }
  }
  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .cdk-drag-placeholder {
    opacity: 0;
  }

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}
