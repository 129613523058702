@use '@angular/material' as mat;
@use "variables" as var;
@use 'sass:map';
@use 'themes/themes' as themes;
@use 'themming' as themming;
@use 'themes/theme-mixins' as themMixins;
@use 'overwrites/theme_overwrites' as overwrites;

@include mat.core();

@include mat.chips-typography(themming.$chips-typography);
@include mat.table-typography(themming.$custom-typography);
@include mat.icon-button-typography(themming.$custom-typography);
@include mat.dialog-typography(themming.$dialog-typography);

@include overwrites.other-overwrites();
.theme-common {
  $lilac-theme-config: map.get(themes.$themes, common);
  @include themMixins.init-theme($lilac-theme-config, true);
  @include themMixins.mat-button-text-color(map.get($lilac-theme-config, isDark));
  .theme-dark {
    color: var.$dark-primary-text;
    a {
      color: darken(var.$dark-primary-text, 5);
      &:hover, :active {
        color: var.$dark-primary-text
      }
    }
  }
}



