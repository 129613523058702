@use '../themes/theme-mixins' as themeMixins;
@use '../mixins' as mixins;
@use '../variables' as var;
@use 'sass:map';

.mat-big-icon {
  @include mixins.mat-icon-size(map.get(var.$portal_spacers, 5) !important);
  padding: 0!important;

  &.grey-color {
    color: var.$color_4; // TODO: replace with $mat-grey variable from "theming.scss"
  }
  &.products-color {
    @include themeMixins.theme-color(primary, 300);
  }
}
