@use '../themes/theme-mixins' as themeMixins;

@mixin mat-mdc-date-picker-overwrites {
  .mat-datepicker-content {
    &.mat-primary {
      @include _mat-date-picker-color-overwrites("primary");
    }
    &.mat-accent {
      @include _mat-date-picker-color-overwrites("accent");
    }
    &.mat-warn {
      @include _mat-date-picker-color-overwrites("warn");
    }
  }
}

@mixin _mat-date-picker-color-overwrites($palette) {
  .mat-calendar-body-in-range::before {
    background: themeMixins.get-theme-color(500, 0.2, $palette);
  }
  .mat-calendar-body-cell:not(
      .mat-calendar-body-disabled
    ).mat-calendar-body-active
  > .mat-calendar-body-today {
    color: themeMixins.get-theme-color(500, null, $palette, true);
  }
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    background: themeMixins.get-theme-color(500, 0.3, $palette);
  }
}
