$app-wrapper-class: 'project-janus';

$star-inline-color: #f69c00;

$color_1: #242424; //Transform to $mat-grey
$color_2: #69707D; //Transform to $mat-grey
$color_3: #c4c4c4; //Transform to $mat-grey
$color_4: #979EAC; //Transform to $mat-grey
$color_5: #EFF0F3; //Transform to $mat-grey

// Sizes

$typography: (
  extra-small: 10px,
  small: 12px,
  normal: 14px,
  medium: 16px,
  large: 22px,
  extra-large: 30px,
  extra-large-medium: 36px,
  extra-large-plus: 40px,
);

$icon-size: 24px;

$font_1: "Open Sans", sans-serif;

$small: 600px;
$average: 1024px;
$large: 1200px;
$extralarge: 1600px;


$portal_spacer: 1rem !default;
$portal_spacers: (
  0: 0,
  1: ($portal_spacer * .25),
  2: ($portal_spacer * .5),
  3: $portal_spacer,
  4: ($portal_spacer * 1.5),
  5: ($portal_spacer * 3 )
);

$light-primary-text: #ffffff;
$dark-primary-text: rgba(#ffffff, 0.87);


$primary-color-dark: (
  50: #e3f2fd,
  100: #63A4F7, // light
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: #63A4F7, // normal
  600: #1e88e5,
  700: #2e0051, // dark
  800: #1565c0,
  900: #004B93,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,

  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$primary-color-dark-lilac: (
  50 : #f4eaf5,
  100 : #e4cae5,
  200 : #d2a7d4,
  300 : #c084c2,
  400 : #b369b5,
  500 : #a54fa8,
  600 : #9d48a0,
  700 : #933f97,
  800 : #8a368d,
  900 : #79267d,
  A100 : #fcbeff,
  A200 : #fa8bff,
  A400 : #f758ff,
  A700 : #f63fff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$secondary-color-dark: (
  50: #e8f5e9,
  100: #70FFAC, // light
  200: #a5d6a7,
  300: #81c784,
  400: #66bb6a,
  500: #2ED47C, // normal
  600: #43a047,
  700: #388e3c, // dark
  800: #2e7d32,
  900: #1b5e20,
  A100: #b9f6ca,
  A200: #69f0ae,
  A400: #00e676,
  A700: #00c853,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);


$mat-grey: (
  50: #EFF0F3, // $color_5
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #c4c4c4, // $color_3
  500: #979EAC, // $color_4
  600: #69707D, // $color_2
  700: #545A67,
  800: #424242,
  900: #242424, // $color_1
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A500: #C3C6CC,
  A700: #616161,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);


$mat-orange: (
  50: #fff3e0,
  100: #ffe0b2, // light
  200: #ffcc80,
  300: #FFB280,
  400: #ffa726,
  500: #ff9800, // normal
  600: #fb8c00,
  700: #FF8533, // dark
  800: #ef6c00,
  900: #e65100,
  A100: #ffd180,
  A200: #ffab40,
  A400: #ff9100,
  A700: #ff6d00,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: black,
  )
);

$mat-red: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);
